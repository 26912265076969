export const APP_NAME = "HRM";
export const DEFAULT_COLOR = "#000000";
export const SECONDARY_DEFAULT_COLOR = "#E9A737";

export const DRAWER_WIDTH_LOW = 90;
export const DRAWER_WIDTH_HIGH = 220;
export const DRAWER_HEIGHT = 80;
export const DISPLAY_NAME = `Shishu Sarothi MIS`;
export const PRIMARY_COLOR = `#2A75BB`;

export const SERVER_NAME =
  process.env.NODE_ENV === "production"
    ? "https://api.ss-mis.org/public/public"
    : "http://localhost/shishu-sarothi-mis/api/public";
export const SERVER_NAME_FOR_UPLOADS =
  process.env.NODE_ENV === "production"
    ? "https://api.ss-mis.org/public"
    : "http://localhost/shishu-sarothi-mis/api/public";

export const BaseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.ss-mis.org/public"
    : "http://localhost/shishu-sarothi-mis/api/public";
export const UploadPath =
  process.env.NODE_ENV === "production"
    ? "https://api.ss-mis.org/src/uploads"
    : "http://localhost/shishu-sarothi-mis/api/src/uploads";

export const WS_SERVER =
  process.env.NODE_ENV === "production"
    ? "wss://monitor.corexx.in"
    : "http://localhost:4004";

export default function number2text(value) {
  var fraction = Math.round(frac(value) * 100);
  var f_text = "";

  if (fraction > 0) {
    f_text = "and " + convert_number(fraction) + " paise ";
  }

  //return convert_number(value)+" rupee "+f_text+" only";
  return convert_number(value) + " rupees " + f_text + " Only.";
}

function frac(f) {
  return f % 1;
}

function convert_number(number) {
  if (number < 0 || number > 999999999) {
    return "NUMBER OUT OF RANGE!";
  }
  var Gn = Math.floor(number / 10000000); /* Crore */
  number -= Gn * 10000000;
  var kn = Math.floor(number / 100000); /* lakhs */
  number -= kn * 100000;
  var Hn = Math.floor(number / 1000); /* thousand */
  number -= Hn * 1000;
  var Dn = Math.floor(number / 100); /* Tens (deca) */
  number = number % 100; /* Ones */
  var tn = Math.floor(number / 10);
  var one = Math.floor(number % 10);
  var res = "";

  if (Gn > 0) {
    res += convert_number(Gn) + " crore";
  }
  if (kn > 0) {
    res += (res == "" ? "" : " ") + convert_number(kn) + " lakh";
  }
  if (Hn > 0) {
    res += (res == "" ? "" : " ") + convert_number(Hn) + " thousand";
  }

  if (Dn) {
    res += (res == "" ? "" : " ") + convert_number(Dn) + " hundred";
  }

  var ones = Array(
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen"
  );
  var tens = Array(
    "",
    "",
    "Twenty",
    "Thirty",
    "Fourty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety"
  );

  if (tn > 0 || one > 0) {
    if (!(res == "")) {
      res += " and ";
    }
    if (tn < 2) {
      res += ones[tn * 10 + one];
    } else {
      res += tens[tn];
      if (one > 0) {
        res += "-" + ones[one];
      }
    }
  }

  if (res == "") {
    res = "zero";
  }
  return res;
}

export const ACCESS_LIST = [
  "PROJECT_MANAGEMENT",
  "INDENT_RECOMMENDATION",
  "INDENT_RECOMMENDATION_GRANT",
  "INDENT_RECOMMENDATION_GRANT_SEC",
  "INDENT_RECOMMENDATION_ACCOUNTS",
  "INVOICE_RECOMMENDATION",
  "INVOICE_RECOMMENDATION_GRANT",
  "INVOICE_RECOMMENDATION_ACCOUNTS",
  "ACCOUNTS_ACCESS",
  "REPORT_ACCESS",
];

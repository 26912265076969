import React, { Suspense, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Router, View } from "react-navi";
import Layout from "./Layout";
import Routes from "./Routes/routes";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import { authService } from "./Helper/AuthService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import HelmetProvider from "react-navi-helmet-async";
import { getMessage, initiateSocket, showNotification } from "./redux/actions";
import store from "./redux/store/index";
import { useDispatch, useSelector } from "react-redux";
import { T_STATUS_SUCCESS } from "./redux/constants/action-types";
import NotificationSnack from "./Components/Essentials/NotificationSnack";
import { notification } from "antd";

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: orange[500],
      contrastText: "#fff",
    },
    primary: {
      main: "#4285F3",
    },
  },
});

toast.configure({
  autoClose: 3000,
  draggable: false,
  pauseOnHover: true,
  position: "bottom-right",
});

// store.dispatch(initiateSocket());
notification.config({
  duration: 2,
});

function App() {
  const [currentUser, setCurrentUser] = useState(() =>
    authService.getCurrentUser()
  );

  const dispatch = useDispatch();

  // const socket = useSelector(state => state.socket)
  // React.useEffect(() => {
  //   if (currentUser) {
  //     socket.emit().emit(`addnewemployee`, {
  //       name: currentUser.role,
  //       emp_id: currentUser.email
  //     })
  //   }

  // }, [currentUser])
  // React.useEffect(() => {
  //   const observable = socket.myType1("senddatatoadmin1");
  //   observable.subscribe(({ message }) => {
  //     //console.log("🚀 ~ file: App.js ~ line 47 ~ observable.subscribe ~ data", message)

  //     if (message) {
  //       if (message.type == "MESSAGE") dispatch(getMessage());

  //       dispatch(
  //         showNotification({
  //           type: T_STATUS_SUCCESS,
  //           status: message.title,
  //           text: message.body,
  //           action: message.action,
  //           link: message.link,
  //         })
  //       );
  //       const audio = new Audio("/resources/pristine.mp3");
  //       audio.play();
  //     }
  //   });
  // }, []);
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Router routes={Routes} context={{ authService, currentUser }}>
          <NotificationSnack />

          <Layout>
            <Suspense fallback={null}>
              <View />
            </Suspense>
          </Layout>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;

import { NOTIFICATION_ON, NOTIFICATION_OFF, LOAD_PARTY, ADD_PARTY, EDIT_PARTY, ACCESS, TOGGLE_COLLAPSE, GET_SERVICES, ADD_TEST, GET_BANKS, GET_CATEGORY, ADD_NEW_TERM, GET_COMPANY_DETAILS, GET_BRANCH, GET_DEPARTMENT, GET_DESIGNATION, GET_GRADE, GET_EMPLOYMENT_TYPE, GET_INSURANCE, GET_LEAVE_TYPE, GET_GRIEVANCE_TYPE, GET_SESSION, GET_HOLIDAY, GET_NOTICE, GET_EMPLOYEE, GET_EMPLOYEE_LEAVE, GET_EMPLOYEE_ATTENDANCE, SOCKET_ON, GET_PAYHEAD, GET_MESSAGE, GET_PAYROLL, GET_FESTIVAL_ADVANCE, GET_PAYMENT_RECEIPT, GET_TEMPLATES} from "../constants/action-types";

const initialState = {
    notification_type: '',
    notification_status: '',
    notification_text: '',
    notification_action:false,
    notification_link:'',
    selectedKeys: ['home'],


    collapse: false,
    drawerWidth: 120,

    companyDetails: {},
    isCompanyDetailsLoaded: false,


    branch: [],
    isBranchLoaded: false,


    department: [],
    isDepartmentLoaded: false,


    designation: [],
    isDesignationLoaded: false,


    linkTable: [],
    isLinkTableLoaded: false,

    grade: [],
    isGradeLoaded: false,

    payhead: [],
    isPayheadLoaded: false,

    payroll: [],
    isPayrollLoaded: false,


    employment_type: [],
    isEmploymentTypeLoaded: false,

    insurance: [],
    isInsuranceLoaded: false,

    leave_type: [],
    isLeaveTypeLoaded: false,

    grievance_type: [],
    isGrievanceTypeLoaded: false,

    session: [],
    isSessionLoaded: false,

    holiday: [],
    isHolidayLoaded: false,

    notice: [],
    isNoticeLoaded: false,

    festival_advance: [],
    isFestivalAdvanceLoaded: false,

    employee: [],
    isEmployeeLoaded: false,

    employee_leave: [],
    isEmployeeLeaveLoaded: false,

    employee_attendance: [],
    isEmployeeAttendanceLoaded: false,

    payment_receipt: [],
    isPaymentReceiptLoaded: false,


    socket: {},
    message:[],
    isMessageLoaded:false


};
function rootReducer(state = initialState, action) {



    if (action.type === NOTIFICATION_ON) {
        return Object.assign({}, state, {
            notification_type: action.payload.type,
            notification_status: action.payload.status,
            notification_text: action.payload.text,
            notification_action: action.payload.action,
            notification_link: action.payload.link
        });
    }

    if (action.type === NOTIFICATION_OFF) {
        return Object.assign({}, state, {
            notification_type: '',
            notification_status: '',
            notification_text: '',
            notification_action:false,
            notification_link:''
        });
    }


   
    if (action.type === GET_COMPANY_DETAILS) {
        return Object.assign({}, state, {
            companyDetails: action.payload,
            isCompanyDetailsLoaded: true
        });
    }


    // Party
    if (action.type === GET_BRANCH) {
        return Object.assign({}, state, {
            branch: action.payload,
            isBranchLoaded: true
        });
    }

    //Department
    if (action.type === GET_DEPARTMENT) {
        
        return Object.assign({}, state, {
            department: action.payload,
            isDepartmentLoaded: true
        });
    }

    //Designation
    if (action.type === GET_DESIGNATION) {
        
        return Object.assign({}, state, {
            designation: action.payload,
            isDesignationLoaded: true
        });
    }


     //linkTable
     if (action.type === GET_TEMPLATES) {
        
        return Object.assign({}, state, {
            linkTable: action.payload,
            isLinkTableLoaded: true
        });
    }


 //Grade
 if (action.type === GET_GRADE) {
        
    return Object.assign({}, state, {
        grade: action.payload,
        isGradeLoaded: true
    });
}

 //Payhead
 if (action.type === GET_PAYHEAD) {
        
    return Object.assign({}, state, {
        payhead: action.payload,
        isPayheadLoaded: true
    });
}

//Payhead
if (action.type === GET_PAYROLL) {
        
    return Object.assign({}, state, {
        payroll: action.payload,
        isPayrollLoaded: true
    });
}


 //Employment Type
 if (action.type === GET_EMPLOYMENT_TYPE) {
        
    return Object.assign({}, state, {
        employment_type: action.payload,
        isEmploymentTypeLoaded: true
    });
}

 //Insurance
 if (action.type === GET_INSURANCE) {
        
    return Object.assign({}, state, {
        insurance: action.payload,
        isInsuranceLoaded: true
    });
}

 //Leave Type
 if (action.type === GET_LEAVE_TYPE) {
        
    return Object.assign({}, state, {
        leave_type: action.payload,
        isLeaveTypeLoaded: true
    });
}

 //Session
 if (action.type === GET_SESSION) {
        
    return Object.assign({}, state, {
        session: action.payload,
        isSessionLoaded: true
    });
}

//Holiday
if (action.type === GET_HOLIDAY) {
        
    return Object.assign({}, state, {
        holiday: action.payload,
        isHolidayLoaded: true
    });
}

//Notice
if (action.type === GET_NOTICE) {
        
    return Object.assign({}, state, {
        notice: action.payload,
        isNoticeLoaded: true
    });
}

//Festival Advance
if (action.type === GET_FESTIVAL_ADVANCE) {
        
    return Object.assign({}, state, {
        festival_advance: action.payload,
        isFestivalAdvanceLoaded: true
    });
}


//Payment Receipt
if (action.type === GET_PAYMENT_RECEIPT) {
        
    return Object.assign({}, state, {
        payment_receipt: action.payload,
        isPaymentReceiptLoaded: true
    });
}

//Employee
if (action.type === GET_EMPLOYEE) {
        
    return Object.assign({}, state, {
        employee: action.payload,
        isEmployeeLoaded: true
    });
}


// //Employee Leave
// if (action.type === GET_EMPLOYEE_LEAVE) {
        
//     return Object.assign({}, state, {
//         employee_leave: action.payload,
//         isEmployeeLeaveLoaded: true
//     });
// }


//Employee Attendance
if (action.type === GET_EMPLOYEE_ATTENDANCE) {
        
    return Object.assign({}, state, {
        employee_attendance: action.payload,
        isEmployeeAttendanceLoaded: true
    });
}

if (action.type === GET_MESSAGE) {
    return Object.assign({}, state, {
        message: action.payload,
        isMessageLoaded: true
    });
}

if (action.type === SOCKET_ON) {
    return Object.assign({}, state, {
        socket: action.payload
    });
}

    //do not delete
    if(action.type === TOGGLE_COLLAPSE){
        let dw = 200;
        if(state.collapse){
            dw = 80;
        }else{
            dw = 200
        }

        if(action.payload === null){
            return Object.assign({}, state, {
                collapse: !state.collapse,
                drawerWidth: dw
             });
        }else{
            return Object.assign({}, state, {
                collapse: action.payload,
                drawerWidth: dw
             });
        }
        
    }



    return state;
}
export default rootReducer;
import React, { useState } from "react";
import { useNavigation } from "react-navi";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Typography, TextField, Button } from "@material-ui/core";
import { PostData } from "../Service/service";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      backgroundColor: "#3D2D70",
    },
    fontLarge: {
      fontSize: 25,
      fontWeight: "bold",
      color: "black",
    },
    card: {
      backgroundColor: "white",
      minHeight: 400,
      width: 400,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default function LoginPage({ currentUser, authService }) {
  const [username, setUsername] = useState("superadmin");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  let navigation = useNavigation();

  function onSubmit(e) {
    e.preventDefault();

    if (username != "" && password != "") {
      // login and flush the error
      setIsLoading(true);

      PostData("/login", { email: username, password: password }).then(
        (resp) => {
          authService.setTokenAfterLogin(resp).then((resp) => {
            setIsLoading(false);
            if (resp == true) {
              navigation.navigate("/mis").then(() => {
                window.location.reload();
              });
            } else {
              setError(resp);
            }
          });
        }
      );
    } else {
      setError("Please insert all fields");
    }
  }
  return (
    <React.Fragment>
      <div className={classes.root}>
        <div align="center">

          <div className={classes.card}>
            <div>
              <img src={`/logo512.png`} style={{ width: 80, marginBottom: 10 }} />
              <Typography
                variant="h1"
                className={classes.fontLarge}
                gutterBottom
              >
                Shishu Sarothi MIS
              </Typography>
            </div>

            <form onSubmit={onSubmit}>
              <div align="left" style={{ width: "80%" }}>
                <TextField
                  required
                  error={Boolean(error)}
                  fullWidth
                  label="Username/Email"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />

                <TextField
                  required
                  type="password"
                  fullWidth
                  error={Boolean(error)}
                  label="Password"
                  className={classes.textField}
                  margin="normal"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />

                <div align="right">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    Login
                  </Button>
                </div>
                {error != "" ? (
                  <Typography style={{ color: "red" }} variant="caption">
                    {JSON.stringify(error)}
                  </Typography>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
